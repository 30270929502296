export const arabic_arr =[
    {key:"Cat and Ghosts",value:"القط والأشباح"},
    {key:"shooting gallery",value:"معرض الرماية"},
    // {key:"Cat and Ghosts",value:"Paka na Mizimu"},
    {key:"Nukkad Cricket",value:"نوكاد للكريكيت"},
    {key:"Tetris Cube",value:"مكعب تتريس"},
    {key:"Christmas Panda Run",value:"تشغيل عيد الميلاد الباندا"},
    {key:"SuDoKu",value:"سودوكو"},
    {key:"christmas adventure",value:"مغامرة عيد الميلاد"},
    {key:"Nature Strikes Back",value:"الطبيعة تضرب مرة أخرى"},
    {key:"Super Cowboy Run",value:"تشغيل سوبر كاوبوي"},
    {key:"Foodie match",value:"مباراة عشاق الطعام"},
    {key:"Ice Cream Memory",value:"ذاكرة الآيس كريم"},
    {key:"Jigsaw Puzzle",value:"أحجية جقسو"},
    {key:"Lucky Wheels",value:"عجلات الحظ"},
    {key:"Math Rush",value:"الرياضيات راش"},
    {key:"Pops Billiards",value:"بوبس بلياردو"},
    {key:"Quiz Game",value:"لعبة مسابقة"},
    {key:"safe-avoid",value:"تجنب الآمن"},
    {key:"Poker",value:"لعبة البوكر"},
    {key:"BlueBlox2",value:"بلو بلوكس2"},
    {key:"Blocker",value:"مانع"},
    {key:"Happy haloween",value:"عيد رعب سعيد"},
    {key:"Air Warfare",value:"الحرب الجوية"},
    {key:"Traffic command",value:"أمر المرور"},
    {key:"Mad Scientist",value:"عالم مجنون"},
    {key:"Santa Run",value:"سانتا رن"},
    {key:"Angry cat school",value:"مدرسة القطط الغاضبة"},
    {key:"Handless Millionaire",value:"مليونير بلا يد"},
    {key:"Stack Jump",value:"كومة القفز"},
    {key:"Gold miner",value:"منقب عن الذهب"},
    {key:"Pool 8 Ball",value:"بلياردو 8 بول"},
    {key:"Clash of battles",value:"صراع المعارك"},
    {key:"Find a Pair Animals",value:"العثور على زوج من الحيوانات"},
    {key:"Puzzle for kids",value:"لغز للأطفال"},
    {key:"Math Pop",value:"الرياضيات البوب"},
    {key:"Wood Slide",value:"الشريحة الخشبية"},
    {key:"Terror-Shooter",value:"مطلق النار الإرهابي"},
    {key:"Stick Panda",value:"عصا الباندا"},
    {key:"Silly Bombs",value:"القنابل السخيفة"},
    {key:"shooting gallery",value:"معرض الرماية"},
    {key:"Shoot Robbers",value:"تبادل لاطلاق النار اللصوص"},
    {key:"Scary Run",value:"تشغيل مخيف"},
    {key:"Save Planet",value:"إنقاذ الكوكب"},
    {key:"samup",value:"samup"},
    {key:"Robber Run",value:"تشغيل السارق"},
    {key:"Road Racer",value:"متسابق الطريق"},
    {key:"ranger vs zombies",value:"الحارس مقابل الزومبي"},
    {key:"Rail Rush",value:"راش السكك الحديدية"},
    {key:"Piggy Roll",value:"رول بيجي"},
    {key:"Panic Drop",value:"قطرة الذعر"},
    {key:"Ninja Run",value:"تشغيل النينجا"},
    {key:"Launcher",value:"منصة الإطلاق"},
    {key:"Hell On Duty",value:"الجحيم على واجب"},
    {key:"Great Air Battles",value:"معارك جوية كبيرة"},
    {key:"Goof Runner",value:"عداء أبله"},
    {key:"Ghost-Dropper",value:"شبح القطارة"},
    {key:"Fruity snake",value:"ثعبان فاكهي"},
    {key:"Flapcat Steampunk",value:"فلابكات ستيمبانك"},
    {key:"Escape",value:"يهرب"},
    {key:"Dragon Jump",value:"قفزة التنين"},
    {key:"Dont Touch the Hooks",value:"لا تلمس الخطافات"},
    {key:"Dices jump",value:"النردات تقفز"},
    {key:"Dark Village",value:"قرية الظلام"},
    {key:"Crazy Car",value:"سيارة مجنونة"},
    {key:"Chess Tower",value:"برج الشطرنج"},
    {key:"Arcade ",value:"ممر"},
    {key:"Puzzle",value:"لغز"},
    {key:"Racing",value:"سباق"},
    {key:"Action",value:"فعل"},
    {key:"Funny",value:"مضحك"},
    {key:"Sports",value:"رياضات"},
    {key:"Adventure",value:"مفامرة"},
    {key:"Kids",value:"أطفال"}
]